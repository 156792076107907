<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Church Presentation Details</h1>
    </page-header>

    <page-body>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">
          {{ translations.tcChurchPresentationDetails }}
        </h1>
        <div class="d-flex mb-2">
          <i class="mr-3">
            <i-map-point/>
          </i>
          <h6>{{ spfd.panel_one.church_name }}</h6>
        </div>
        <div class="address mb-3">
          <span>{{ spfd.panel_one.address }}</span>
          <br />
          <span>{{ spfd.panel_one.city }}</span>
          <br />
          <span>{{ spfd.panel_one.country }}</span>
        </div>
        <div class="d-flex mb-3">
          <i class="mr-3">
            <i-calendar/>
          </i>
          <p>{{ translatedDate }}</p>
        </div>
        <div class="d-flex">
          <span class="label font-style-1 mr-2">{{ translations.tcStatus }}:</span>
          <span class="value font-weight-bold">
            {{ spfd.panel_one.status }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcToolTip">
              <i-tooltip />
            </span>
          </span>
        </div>
      </section>

      <section class="section-2 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">
            {{ translations.tcPresentationTimesSpeaker }}
          </h2>
          <ToggleArrow @click="section2 = !section2" section="toggleMe" :status="section2"></ToggleArrow>
        </header>
        <div class="body" v-if="section2">
          <div class="presentation-specs" v-for="(pnl2, indx) in spfd.panel_two" :key="indx">
            <div class="font-weight-bold">
              <span class="value">{{ pnl2.service_time }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcSpeaker }}:</span>
              <span class="value">{{ pnl2.speaker }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcAttire }}:</span>
              <span class="value">{{ pnl2.attire }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcMedia }}:</span>
              <span class="value">{{ pnl2.media }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcMinutes }}:</span>
              <span class="value">{{ pnl2.minutes }}</span>
            </div>
          </div>
        </div>
      </section>

      <section class="section-3 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">
            {{ translations.tcPresentationDetails }}
          </h2>
          <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
        </header>
        <div class="body" v-if="Section3">
          <div class="presentation-details">
            <div class="font-weight-bold">
              <span class="label"> {{ translations.tcSpeakerArrivalTime }}: </span>
              <span class="value">{{ spfd.panel_three.arrival }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcPrayerPartner }}:</span>
              <span class="value">{{ spfd.panel_three.partner }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcContactedBy }}:</span>
              <span class="value">{{ spfd.panel_three.contacted_by }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label"> {{ translations.tcSpeakerShouldContact }}: </span>
              <span class="value">{{ spfd.panel_three.should_contact }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label"> {{ translations.tcBulletinsRequired }}: </span>
              <span class="value">{{ spfd.panel_three.bulletins }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcAttendance }}:</span>
              <span class="value">{{ spfd.panel_three.attendance }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label">{{ translations.tcOffering }}:</span>
              <span class="value">{{ spfd.panel_three.offering_amount }}</span>
            </div>
            <div class="font-weight-bold">
              <span class="label"> {{ translations.tcOfferingAgreeable }}: </span>
              <span class="value">{{ spfd.panel_three.offering_agreeable === true ? translations.tcYes || 'Yes' : translations.tcNo || 'No' }}</span>
            </div>
          </div>
        </div>
      </section>

      <section class="section-4 bg-block mb-4">
        <header class="mb-4">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <p class="pb-3">{{ spfd.panel_one.other }}</p>
        </div>
      </section>

      <section class="section-5 pt-3 pl-0 pl-sm-5">
        <div class="body">
          <div class="d-flex mb-0 mb-sm-3">
            <b-button
              @click.prevent="downloadReport(getPresentationEnvelopeURL, translations.tcPresentationEnvelope, 'pdf')"
              variant="secondary"
              class="flex-0 mr-0 mr-sm-3 mb-3 w-100-sd mb-sm-0"
            >
              {{ translations.tcPresentationEnvelope }}
            </b-button>
            <b-button
              @click.prevent="downloadReport(getSpeakerEvaluationURL, translations.tcSpeakerEvaluation, 'pdf')"
              variant="secondary"
              class="flex-0 mr-0 mr-sm-3 mb-3 w-100-sd mb-sm-0"
            >
              {{ translations.tcSpeakerEvaluation }}
            </b-button>
            <b-button
              :href="pastorConfirmationLetter"
              download
              variant="secondary"
              class="flex-0 mr-0 mr-sm-3 mb-3 w-100-sd mb-sm-0"
            >
              {{ translations.tcPastorConfirmation }}
            </b-button>
          </div>
          <div class="d-flex mb-0 mb-sm-3">
            <b-button
              :href="getThankYouLetterURL"
              download
              variant="secondary"
              class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
            >
              {{ translations.tcThankYouLetter }}
            </b-button>
            <b-button
              @click.prevent="downloadReport(getNumber10EnvelopeURL, translations.tcNumber10Envelope.replace('.',''), 'pdf')"
              variant="secondary"
              class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
            >
              {{ translations.tcNumber10Envelope }}
            </b-button>
          </div>
          <div class="d-flex action-buttons">
            <b-button
              v-if="iCanSee(secured_presentation_edit_controls.edit_church_presentation_button)"
              variant="primary"
              @click="handleEditButtonClick"
              class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
            >
              {{ translations.tcEdit }}
            </b-button>
            <b-button variant="tertiary" @click="handleCancelButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd">
              {{ translations.tcCancel }}
            </b-button>
            <b-button
              v-if="iCanSee(secured_presentation_delete_controls.delete_church_presentation_button)"
              variant="primary"
              @click="handleDeleteButtonClick"
              class="flex-0 mr-0 mr-sm-3 w-100-sd"
            >
              {{ translations.tcDelete }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import date_data from '@/json/date.json'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iMapPoint from '@/assets/svgs/iMapPoint.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'

export default {
  name: 'view-church-presentation',
  mixins: [translationMixin,reportDownloadMixin],
  data() {
    return {
      translatedDate: '',
      translations: {},
      toggle: true,
      section2: true,
      Section3: true,
      spfd: {
        panel_one: {
          church_name: '',
          address: '',
          city: '',
          country: '',
          date: '',
          status: '',
          other: '',
        },
        panel_two: [],
        panel_three: {},
      },
      secured_presentation_edit_controls: {
        edit_church_presentation_button: '4a46430d-1b87-4144-90c4-1cc8aea01112',
      },
      secured_presentation_delete_controls: {
        delete_church_presentation_button: 'a604e117-b657-4f94-8cf0-06ee0f1c5984',
      },
    }
  },
  methods: {
    ...mapActions({
      clearPresentationTimeData: 'churchMinistry/clearPresentationTimeData',
      clearSinglePresentationForDisplay: 'churchMinistry/clearSinglePresentationForDisplay',
      deletePresentation: 'churchMinistry/deletePresentation',
      loadSinglePresentationForDisplay: 'churchMinistry/loadSinglePresentationForDisplay',
      loadPresentationDocumentUrls: 'churchMinistry/loadPresentationDocumentUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPresentationEditKey: 'churchMinistry/setPresentationEditKey',
    }),
    async handleEditButtonClick() {
      await this.setPresentationEditKey(this.userSelectedMeetingKey)
      this.$router.push('/programs/cm/churchProfile/add-church-presentation')
    },
    async handleCancelButtonClick() {
      await this.clearPresentationTimeData()
      await this.clearSinglePresentationForDisplay()
      this.$router.push('/programs/cm/presentations')
    },
    async handleDeleteButtonClick() {
      let result = true
      await Promise.all([
        this.setLoadingStatus(true),
        (result = await this.deletePresentation(this.userSelectedMeetingKey)),
      ]).then(() => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result ? 'success' : 'error',
          text: result
            ? this.translations.tcPresentationDeleted || 'The presentation was deleted.'
            : this.translations.tcErrorDuringSave || 'An error occurred during the save process. The data was not deleted.',
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          if (result.value) {
            this.$router.push('/programs/cm/presentations')
          }
        })
      })
    },
    async page_load() {
      this.daysJson = date_data.days
      this.monthsJson = date_data.months
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getTranslations(),
        this.getComponentTranslations(
          'common.attire',
          'common.church-service-type',
          'common.days',
          'common.meeting-status',
          'common.months',
          'common.presentation-types'
        ),
        await this.loadPresentationDocumentUrls(this.userSelectedMeetingKey),
        await this.loadSinglePresentationForDisplay(this.userSelectedMeetingKey),
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        this.spfd = this.single_presentation_for_display
        this.updateDynamicText()
        this.setLoadingStatus(false)
      })
    },
    updateDynamicText () {
      // Update Date and Status in `panel_one`
      const splitDate = this.spfd.panel_one.date.split(' ')
      const tempMonth = splitDate[0] ? this.getMonthGuid(splitDate[0].trim()) : {}
      const translatedMonth = this.translations.common.months[tempMonth.guidFull] || ''
      this.translatedDate = `${translatedMonth} ${splitDate[1]} ${splitDate[2]}`

      const panelOneStatus = this.spfd.panel_one.status
      const panelOneStatusKey = this.spfd.panel_one.status_key
      this.single_presentation_for_display.panel_one.status = this.translations.common['meeting-status'][panelOneStatusKey] || panelOneStatus

      // Update Service Time, Attire, and Media Presentation Type in `panel_two`
      if (this.spfd.panel_two[0].service_type_key && this.spfd.panel_two[0].weekday_key) {
        const { service_type_key, weekday_key } = this.spfd.panel_two[0]
        const serviceType = this.translations.common['church-service-type'][service_type_key] || ''
        const weekday = this.translations.common['days'][weekday_key] || ''
        const originalServiceTime = this.spfd.panel_two[0].service_time.split(', ')[1]
        this.spfd.panel_two[0].service_time = `${serviceType} - ${weekday}, ${originalServiceTime}`
      }
      this.spfd.panel_two[0].attire = this.translations.common.attire[this.spfd.panel_two[0].attire_key] || this.spfd.panel_two[0].attire
      this.spfd.panel_two[0].media = this.translations.common['presentation-types'][this.spfd.panel_two[0].media_type_key] || this.spfd.panel_two[0].media
    },
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      getNumber10EnvelopeURL: 'churchMinistry/number_10_envelope_url',
      getPresentationEnvelopeURL: 'churchMinistry/presentation_envelope_url',
      getSpeakerEvaluationURL: 'churchMinistry/speaker_evaluation_url',
      getThankYouLetterURL: 'churchMinistry/thank_you_letter_url',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      singlePresentation: 'churchMinistry/single_presentation',
      single_presentation_for_display: 'churchMinistry/single_presentation_for_display',
      userSelectedMeetingKey: 'user/userSelectedMeetingKey',
    }),
    pastorConfirmationLetter() {
      return constantData.document_prefix_tc3 + constantData.pastor_confirmation_letter
    },
    presentationDate() {
      const { date } = this.spfd.panel_one
      if (isNaN(Date.parse(date))) return null

      return new Date(date)
    },
  },
  components: {
    iCalendar: iCalendar,
    iMapPoint: iMapPoint,
    pageHeader: pageHeader,
    pageBody: pageBody,
    ToggleArrow: ToggleArrow,
    iTooltip: iTooltip,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1 {
  .d-flex {
    align-items: center;
  }
  i {
    width: 22px;
    font-size: 0;
    text-align: center;
    svg {
      display: inline-block;
    }
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .address {
    padding-left: 46px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.presentation-specs,
.presentation-details {
  max-width: 680px;
  columns: 2;
  column-gap: 40px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #979797;
  @include breakpoint(sm) {
    columns: 1;
    column-gap: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  div {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      color: #636363;
      font-weight: 700;
      line-height: 22px;
    }
    .label {
      display: block;
      margin-right: 0.25rem;
      text-transform: uppercase;
    }
  }
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: middle;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    button {
      min-width: 280px;
    }

    &.action-buttons {
      button {
        min-width: inherit;
      }
    }
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
